.ant-popover-inner{
    border-radius: 8px;
}

.interest-filter{
    padding: 16px 24px;
    width: 300px;
    border-radius: 5px;
    
    .interest-filter-header{
        font-size: 18px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid #F0F0F0;
        margin-bottom: 10px;
    }
    
    .interest-filter-options{
        display: flex;
        flex-direction: column;

        .ant-checkbox-group{
            display: flex;
            flex-direction: column;
        }
    }
}

.red-asterisk{
    color:#F00;
}

.content-wrapper{
    @media (max-width: 600.98px) {
        padding-top: 40px!important;
    }
}