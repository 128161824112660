.announcement-wrapper {
  .showAnnouncement {
    top: -57px;
    right: 0;
  }
  button {
    font-size: 16px;
    text-align: center;
    color: #595959;
    height: 40px;
  }
  .announcement-container {
    margin-bottom: 24px;
    min-height: 268px;
    background-color: #002962;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 24px;
    border-radius: 4px;
    .announcement-header {
      display: flex;
      width: 100%;
      justify-content: space-between;
      flex-wrap: wrap;
      h3 {
        color: white;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
      }
    }
    .carousel-item {
      min-height: 160px;
      margin-bottom: 24px;

      h1 {
        font-weight: 500;
        font-size: 38px;
        line-height: 46px;
        color: #ffffff;
      }
      P {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        display: flex;
        align-items: center;
        color: #ffffff;
      }
      button {
        height: 40px;
        border: 1px solid #d9d9d9;
        border-radius: 5px;
        color: #595959;
      }
    }
    .slick-dots {
      li {
        width: 8px !important;
        height: 8px;
        margin-left: 4px;
        margin-right: 4px;

        opacity: 1 !important;
      }
      .slick-active {
        button {
          background: #02b8f6 !important;
        }
      }
      button {
        border-radius: 50% !important;
        width: 8px !important;
        height: 8px !important;
        background: #fafafa !important;
        opacity: 1 !important;
      }
    }
    .slick-dots-bottom {
      bottom: 0 !important;
      margin: 0;
    }
  }
}
