.ant-layout-header-breeze {
  background-color: $breeze-color-main;

  .ant-menu {
    background: transparent;
  }

  .ant-menu-item:hover {
    background-color: $breeze-color-main !important;
  }
  .ant-menu-item-selected {
    background-color: $breeze-color-main !important;
  }
}

.Home-Page-Header {
  height: 120px;
  background-color: white;
  border-bottom: 1px solid gray;
}

.menu-class {
  justify-content: end;
}

.logo-container {
  display: inline-block;
  color: white;
}

.logo-custom {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  // background: rgba(255, 255, 255, 0.2);
  object-fit: fill;
}

// .ant-menu-submenu {
//   min-width: 100% !important;
// }
