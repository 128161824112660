.profile-wrapper {
  height: 100%;
  width: 100%;
  // display: flex;
  // justify-content: center;
  .ant-row {
    justify-content: center;
  }
}
.form-modal-fullview {
  .ant-form-item {
    margin-bottom: 5px !important;
  }
}
.profile-page-single-content-wrapper {
  margin-top: 25px;
  margin-bottom: 25px;
  padding: 0 50px;
  .profile-page-single-content {
    max-width: $wrapper-max-width;
    margin: 0 auto;
    display: block;
    margin-top: 24px;
    hr {
      padding-top: 10px;
      padding-bottom: 10px;
      margin: 0;
      border-color: rgba(0, 0, 0, 0.03);
      border: none;
    }

    .text-head {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 15px;
    }

    .all-link {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      color: #02b8f6;
    }

    .content-left-main {
      .outer-box {
        padding: 24px;
        background: #ffffff;
        border-radius: 8px;
        h3 {
          font-weight: 500;
          font-size: 20px;
          line-height: 28px;
        }
      }
      .profile-ratings {
        .rating-container {
          height: 100%;
          border-radius: 4px;
          background: #fafafa;
          width: 100%;
          display: flex;
          flex-direction: column;
          padding: 16px;
          justify-content: start;
          .anticon {
            display: flex;
            margin-bottom: 10px;
            svg {
              width: 28px;
              // color: #02b8f6;
              height: 28px;
            }
          }

          .rating-points {
            font-weight: 500;
            font-size: 24px;
            line-height: 32px;
            margin: 0;
          }
          .rating-name {
            color: rgba(0, 0, 0, 0.45);
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            margin: 0;
          }
        }
      }
      .profile-softskill {
        .softskill-container {
          padding: 24px;
          margin-top: 24px;
          border-radius: 8px;
          background: white;
          width: 100%;
        }
      }
      .profile-club-interest {
        .profile-interest-container {
          position: relative;
          padding: 24px;
          margin-top: 24px;
          border-radius: 8px;
          background: white;
          width: 100%;
          .interest-container {
            display: flex;
            .interest-item {
              margin-right: 24px;
              height: 130.67px;
              width: 130.67px;
              padding: 16px;
              display: flex;
              flex-direction: column;
              background: #fafafa;
              border-radius: 8px;
              align-items: center;
              justify-content: center;
              .text-gray {
                color: rgba(0, 0, 0, 0.45);
              }
              h4 {
                font-style: normal;
                font-weight: 500;
                text-align: center;
                font-size: 16px;
                line-height: 24px;
              }
              img {
                margin-bottom: 8px;
                width: 48px;
                height: 48px;
              }
              span {
                width: 48px;
                height: 48px;
                margin-bottom: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
              svg {
                color: rgba(0, 0, 0, 0.45);
                width: 18.43px;
                height: 19.29px;
              }
            }
          }
        }
      }
      .profile-upcoming-event {
        .profile-upcoming-event-container {
          position: relative;
          padding: 24px;
          margin-top: 24px;
          border-radius: 8px;
          background: white;
          width: 100%;

          .upcoming-event-item {
            display: flex;
            flex-direction: column;
            .event-image {
              object-fit: cover;
              width: 100%;
              border-radius: 8px 8px 0px 0px;
            }
            .upcoming-event-body {
              background: #fafafa;
              padding: 16px;
              border-bottom-left-radius: 8px;
              border-bottom-right-radius: 8px;
              .event-interest {
                font-weight: 500;
                font-size: 14px;
                line-height: 22px;
                color: rgba(0, 0, 0, 0.45);
                margin-bottom: 0;
              }
              .event-name {
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 28px;
              }
              .event-details {
                color: black;
                display: flex;
                justify-content: space-between;
              }
            }
          }
        }
      }
    }
    .button-edit {
      position: absolute;
      right: 25px;
      top: 25px;
      cursor: pointer;
    }

    .content-left {
      height: 100%;
      padding: 24px;
      background: #ffffff;
      border-radius: 4px;

      .account-setting-container {
        display: flex;
        justify-content: space-between;
        button {
          padding: 8px 24px 8px 24px;
          height: 40px;
        }
        .account-setting {
          display: flex;
          flex-direction: column;
          .text-head {
            margin: 0;
            padding-bottom: 7px;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
          }
          span {
            padding-bottom: 7px;
            color: rgba(0, 0, 0, 0.45);
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
          }
          p {
            padding-bottom: 7px;
            margin: 0;
            color: rgba(0, 0, 0, 0.45);
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
    .content-right-main {
      height: 100%;
      .profile-calendar-container {
        position: relative;
        padding: 24px;
        height: 100%;
        border-radius: 8px;
        background: white;
        width: 100%;
        .ant-picker-cell {
          width: 52px;
          height: 45px;
          .ant-picker-calendar-date-value {
            padding: 11.5px 0;
          }
        }
        .ant-radio-button-wrapper-checked {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
          padding-left: 5px;
          padding-right: 5px;

          border-color: #02b8f6;
        }

        .ant-picker-calendar-date-today {
          background-color: transparent;
          border-color: #02b8f6;
          color: black;
          padding-left: 13px;
          padding-right: 13px;
          border-radius: 4px;
        }
        .normal-widget {
          h4 {
            margin-bottom: 10px;
            font-size: 20px;
          }

          img {
            margin: 0 10px 0 0;
          }
          h5 {
            margin: 0;
            font-size: 16px;
          }
        }
        .profile-calendar {
          .ant-picker-calendar-mode-switch {
            display: none;
          }
          .ant-picker-cell-selected {
            .ant-picker-calendar-date-value {
              border: 1px solid #02b8f6;
              margin-left: 1px;
              margin-right: 1px;
              margin-top: -1px;
              margin-bottom: -1px;

              border-radius: 2px;
            }
          }
        }
      }
      .profile-club-joined {
        height: 100%;
        padding-bottom: 24px;
        .profile-club-joined-container {
          position: relative;
          padding: 24px;
          margin-top: 24px;
          border-radius: 8px;
          background: white;
          width: 100%;
          height: 100%;

          ul {
            padding: 0;
            list-style: none;
            li {
              margin-bottom: 16px;
              display: flex;
              img {
                border-radius: 4px;
                margin-right: 15px;
              }
              .club-info {
                display: flex;
                flex-direction: column;

                h4 {
                  font-weight: 500;
                  font-size: 16px;
                  color: rgba(0, 0, 0, 0.85);
                  line-height: 24px;
                  margin: 0;
                  text-align: left;
                }

                h5 {
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 22px;
                  margin: 0;
                  color: rgba(0, 0, 0, 0.45);
                  text-align: left;
                }
              }
            }
          }
        }
      }
    }
    .content-right {
      padding: 24px;
      background: #ffffff;
      border-radius: 4px;
      height: 100%;

      .summary-textarea {
        width: 100%;
        overflow: hidden;
        border: 0;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }
      .summary-textarea:disabled {
        background-color: transparent;
      }
      .textarea-active {
        border: 1px solid black;
      }

      .profile-summary {
        position: relative;
        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
        }
      }
      .profile-information {
        position: relative;
        label {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: rgba(0, 0, 0, 0.45);
        }

        h5 {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
        }
      }

      .ant-radio-button-wrapper {
        height: 100%;
        line-height: 38px;

        span {
          color: #02b8f6;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .profile-page-single-content {
      .content-right-main {
        .profile-calendar-container {
          display: none;
        }
      }
    }
  }
}
