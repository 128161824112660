.modal-notify {
  width: 364px !important;

  .ant-modal-content {
    border-radius: 8px;
  }
  .ant-modal-body {
    padding: 20px 23px 2px 23px;
  }

  .mod-title {
    font-size: 16px;
    font-weight: 600;
  }

  .ant-modal-close-x {
    width: 45px;
    height: 45px;
    line-height: 45px;
    font-size: 14px;
  }

  .modal-message {
    font-size: 14px;
  }

  .ant-modal-footer {
    border: 0;
    padding-top: 0;
    padding-bottom: 15px;
  }
  .modal-ul {
    padding-left: 20px;
  }
}
.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.25);
}


.modal-event-item {
  .ant-modal-content {
    border-radius: 8px;
  }
  .ant-modal-body {
    padding: 20px 23px 2px 23px;
  }
  .ant-modal-header {
    border-radius:8px 8px 0px 0px;
  }

  .mod-title {
    font-size: 16px;
    font-weight: 600;
  }

  .ant-modal-close-x {
    width: 45px;
    height: 45px;
    line-height: 45px;
    font-size: 14px;
  }

  .modal-message {
    font-size: 14px;
  }

  .ant-modal-footer {
    // border: 0;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .modal-ul {
    padding-left: 20px;
  }
}