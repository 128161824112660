.modal-fullview {
    width: 100% !important;
    max-width: 100% !important;
    height: 100%;
    top: 0;
    margin: 0;
    padding-bottom: 0;

    .ant-modal-content {
        padding-left: 5%;
        padding-right: 5%;
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-y: scroll;
    }

    .ant-modal-body {
        display: flex;
        padding-top: 100px;
        align-items:flex-start;
        justify-content: center;

        // img {
        //     max-height: 80%;
        // }
    }

    .modal-header-expanded{
        display: flex;
        width: 100%;
        flex-direction: row;
        vertical-align: middle;
        justify-content: center;

        .btnRow-expanded{
            position: absolute;
            right: 5%;
            display: flex;
            flex-direction: row;
            justify-self:end;
        }
    }
   
}
  

.modal-smallview {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);


    .ant-modal-content {
        display: flex;
        flex-direction: column;
        // height: 100%;
        overflow-y: scroll;

        .ant-modal-header {
            height: 100%;
            padding-left: 10px;
            padding-right: 10px;
        }
      
        .ant-modal-body {
            display: flex;
            // max-width: 500px;
            // max-height: 500px;
            align-items: center;
            justify-content: center;


            img.qr{
                width: 300px;
            }
        }

        .ant-modal-footer{
            font-size: 24px;
            text-align: left;
        }
    }

}

.modal-header{
    display: flex;
    width: 100%;
    flex-direction: row;
    vertical-align: middle;
    justify-content: flex-start;

}



.icon-btn{
    height: 25px;
    width: 25px;
    margin-left: 20px;
    border: none;
    box-shadow: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-attendance {
    width: 100% !important;
    max-width: 100% !important;
    height: 100%;
    top: 0;
    margin: 0;
    padding-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    @media (max-width: 850px) {
        overflow-x: scroll;
    }

    .ant-modal-content {
        border-radius: 5px;
        width: 90%;
        display: flex;
        flex-direction: column;
        // overflow-y: scroll;

        .ant-modal-header {
            height: 100%;
            padding-left: 10px;
            padding-right: 10px;
        }
      
        .ant-modal-body {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;

            // img{
            //     width: 300px;
            // }
        }

        .ant-list-header{
            display: none;
        }

        .ant-modal-footer{
           display: none;
        }
    }
}

.btnRow{
    display: flex;
    flex-direction: row;
}

