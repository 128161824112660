.login-page-wrapper,
.signup-page-wrapper {
  height: 100%;
  .panel-container {
    display: flex;
    height: 100%;
    width: 100%;

    @media (max-width: 575.98px) {
      flex-direction: column;
      // height: auto;
    }

    .panel-logo {
      width: 50px;
      height: 50px;
    }

    h2 {
      font-weight: 500;
      font-size: 30px;
      line-height: 40px;
      color: #ffffff;
    }

    .sub-title {
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      color: #ffffff;
    }

    .left-content {
      background-color: #002962;
      padding-left: 2%;
      padding-right: 2%;
      height: 100%;
      @media (min-width: 1400px) {
        width: 25%;
      }
      @media (max-width: 1399px) {
        width: 25%;
      }

      @media (max-width: 1199px) {
        width: 40%;
      }

      @media (max-width: 992px) {
        width: 45%;
      }

      @media (max-width: 767px) {
        img {
          height: auto;
        }
      }

      @media (max-width: 576px) {
        display: none;
        width: 0;
      }

      .login-logo {
        margin-bottom: 40px;
      }
    }

    .blue-bg {
      background-color: $breeze-color-blue-2;
    }

    .right-content {
      padding: 2%;
      @media (min-width: 1400px) {
        width: 75%;
      }
      @media (max-width: 1399px) {
        width: 75%;
      }
      @media (max-width: 1199px) {
        width: 60%;
      }
      @media (max-width: 992px) {
        width: 55%;
      }

      @media (max-width: 576px) {
        width: 100%;
        padding: 0;
      }
      .nav-controls {
        a {
          color: #262626;
        }
        @media (max-width: 576px) {
          padding-left: 18px;
          padding-right: 18px;
        }
        .back-text {
          @media (max-width: 767px) {
            display: none;
          }
        }
      }
      .form-title {
        font-weight: 500;
        font-size: 30px;
        color: rgba(0, 21, 64, 0.85);
        margin-bottom: 5px;
      }
      .sub-title {
        font-weight: 400;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.85);
      }

      @media (max-width: 576px) {
        .form-title {
          font-size: 28px;
        }
      }

      .pin-form {
        .ant-input {
          height: 96px;
          width: 85px;
          border-radius: 0;
          margin-right: 8px;
          text-align: center;
          font-size: 30px;
          @media (max-width: 1399px) {
            height: 76px;
            width: 65px;
          }
          @media (max-width: 992px) {
            height: 60px;
            width: 52px;
          }
          @media (max-width: 767px) {
            height: 50px;
            width: 42px;
            font-size: 25px;
          }
          @media (max-width: 576px) {
            height: 60px;
            width: 52px;
          }
        }
      }

      .text-success {
        color: #52c41a;
      }

      .anticon-loading-sm {
        font-size: 14px !important;
        color: $breeze-light-green;
      }

      .form-panel {
        @media (max-width: 576px) {
          justify-content: space-between;
          border-radius: 15px 15px 0px 0px;
          margin-top: -20px;
          background-color: white;
          z-index: 2;
          margin-bottom: 40px;
        }

        .form-container {
          margin-top: 40px;

          button {
            width: 100%;
          }

          .custom-addon {
            input {
              border-right: 1px solid transparent;
            }

            .ant-input-group-addon {
              background-color: transparent;
              border-left: 1px solid transparent;
            }
          }
        }
      }

      .mobile-header {
        position: relative;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
        padding-bottom: 95px;
        background-position: right 15px top 13px;
        background-color: #0ec1fe;
        background-repeat: no-repeat;
      }
    }
  }
}

.login-page-wrapper.flutter {
  
  .panel-container {
    display: flex;
    justify-content: center;
    background: rgba(2, 184, 246, 1);

    .content {
      width: 100%;
      max-width: 382px;

      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
      padding: 12px;

      .form-panel {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;

        .header {
          display: flex;
          flex-direction: column;
          align-items: center;
  
          img {
            width: 64px;
          }
  
          h2 {
            font-size: 30px;
            font-weight: 500;
            line-height: 40px;
            letter-spacing: 0em;
            text-align: center;
          }
        }

        .form-content {
          display: flex;
          flex-direction: column;
          align-items: center;
  
          width: 100%;
          max-width: 382px;
    
          .form-container {
            width: 100%;
  
            .form-title {
              font-size: 24px;
              font-weight: 500;
              line-height: 32px;
              letter-spacing: 0em;
              text-align: left;
      
              font-size: 24px;
              font-weight: 400;
              line-height: 32px;
              letter-spacing: 0em;
              text-align: left;
      
              font-size: 24px;
              font-weight: 500;
              line-height: 32px;
              letter-spacing: 0em;
              text-align: left;
              color: rgba(0, 41, 98, 1);
            }
  
            .next {
              width: 100%;
              height: 40px;
              padding: 8px, 16px, 8px, 16px;
              border-radius: 12px;
              border: 1px;
              gap: 10px;
  
              background: rgba(0, 41, 98, 1);
              border: 1px solid rgba(0, 41, 98, 1);
            }
  
            .remember-me {
              margin-top: 12px;
              font-size: 14px;
              font-weight: 400;
              line-height: 22px;
              letter-spacing: 0em;
              text-align: left;
              color: rgba(0, 41, 98, 1);
            }

            .forgot-password {
              text-align: right;
              a {
                font-size: 14px;
                font-weight: 400;
                line-height: 22px;
                letter-spacing: 0em;
                text-align: left;
                color: rgba(0, 41, 98, 1) !important;
              }
              margin-top: 12px;
              
            }
          }
  
          
        }
  
        .nav-controls {
          .create-account {
            width: 100%;
            height: 40px;
            padding: 8px, 16px, 8px, 16px;
            border-radius: 12px;
            background: linear-gradient(0deg, #FFFFFF, #FFFFFF),
            linear-gradient(0deg, #D9D9D9, #D9D9D9);
  
            border: 1px solid rgba(217, 217, 217, 1);
            box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
          }
        }
      }
    }
  }
}