@import "../colors.scss";

.event-calendar-title {
  margin: 0;
  background: white;
  padding: 24px 24px 0;
}
.rbc-calendar {
  height: 75vh;
  background: white;
  padding: 24px;
}
.rbc-label {
  width: 70px;
}

.rbc-event {
  background-color: $breeze-color-blue-1;
}

// .rbc-time-header {
//   margin-right: 0 !important;
//   border: 0 !important;
// }

.rbc-toolbar {

  .rbc-toolbar-label {

    @media (max-width: 614px) {
      margin-bottom: 12px;
    }

    margin-left: 10px;
    text-align: left;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.85);

  }
}
.rbc-btn-group {

  @media (max-width: 614px) {
    margin-bottom: 12px;
  }

  button {
    background: transparent !important;
    box-shadow: none !important;
    border: 1px solid #d9d9d9;
    margin: 0;
  }
  .rbc-active {
    color: #02b8f6 !important;
    border: 1px solid #02b8f6 !important;
  }
}
.rbc-month-view {
  border: 0 !important;

  .rbc-header {
    padding: 14px;
    text-align: right;
    font-weight: bold;
    border: 0 !important;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  .rbc-month-row {
    border: 0 !important;
  }

  .rbc-row-segment {
    margin-bottom: 4px;
  }

  .rbc-off-range-bg {
    background: transparent;
  }

  .rbc-day-bg {
    border-left: 0 !important;
    box-shadow: inset 0px 2px 0px #f0f0f0;
    margin-left: 5px;
    margin-left: 5px;
  }

  .rbc-today {
    box-shadow: inset 0px 2px 0px #1890ff;
  }

  .rbc-row-content {
    pointer-events: none;
    .rbc-row {
      pointer-events: none;
    .rbc-button-link.rbc-show-more{
      pointer-events: all;
    }
    .rbc-event-content{
      pointer-events: all;
    }
    .rbc-date-cell {
      // pointer-events: none;
      padding-top: 5px;

      button{
        pointer-events: all;
      }
    }
  }
  }
}

.rbc-current-time-indicator {
  position: absolute;
  z-index: 1;
  left: 0;
  height: 1px;

  background-color: red;
  pointer-events: none;

  &::before {
    display: block;

    position: absolute;
    left: -3px;
    top: -3px;

    content: " ";
    background-color: red;

    border-radius: 50%;
    width: 8px;
    height: 8px;
  }

  .rbc-rtl &::before {
    left: 0;
    right: -3px;
  }
}

.calendarToolbar{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;

  .left-side{
    display: flex;
    flex-direction: row;
    
    @media (max-width: 600.98px) {
      flex-direction: column;
    }

    .nav-btn{
      display: flex;
      flex-direction: row;

      Button{
        padding: 10px;
        display: flex;
        font-size: 12px;
        margin-right: 16px;
        border-radius: 2px;
        align-items: center;
        justify-content: center;
      }
    }

    .mini-calendar-btn{
      border: none;
      padding: 10px;
      display: flex;
      font-size: 12px;
      margin-right: 16px;
      align-items: center;
      box-shadow: none;
      justify-content: center;
      
      h2{
        padding-top: 3px;
        font-size: 16px;
      }

      @media (max-width: 600.98px) {
        margin-top: 10px;
        padding: 0px;
      }
    }
  }

  .dropdown{
    padding: 10px;
    display: flex;
    font-size: 12px;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
  }

}

.mini-calendar{
  width: 300px;

  .ant-picker-cell-selected .ant-picker-cell-inner{
    background-color: #02B8F6;
  }

  .today-btn{
    width: 100%;
    color: #02B8F6;
    border: none;
    box-shadow: 0 -5px 1px -5px #888;
  }
}

.mini-cal-header{
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
}

.currDate-cell{
  width: 100%;
  border-top: 2px solid #1890FF;
  margin-left: 5px;
  margin-right: 5px;
  background-color: #EBFAFF;

}

.date-cell{
  width: 100%;
  border-top: 2px solid #f0f0f0;
  margin-left: 5px;
  margin-right: 5px;

  .overlap-tooltip{
    padding: 4px;
    pointer-events: all;
  }
}


.overlap-alert{
  font-size: 14px;
  color: #FAAD14;
}

.ant-tooltip-inner{
  border-radius: 5px !important;
}

.custom-tooltip{
  padding: 5px 0px;
  border-radius: 5px;
  background-color: #FFF;

  h3{
    padding-left: 10px;
    padding-right: 10px;
    border-bottom: 1px solid #F0F0F0;
  }

  .conflict-h3{
    color:#FA8C16;
  }

  p{
    padding: 5px 10px;
    color: #000;
  }
}

.week-header-cell{
  padding: 5px;
  height: 100px;
  min-width: 1300px;
  text-align: left;

  h1{
    font-weight: 500;
    font-size: 38px;
  }
  h2{
    font-weight: 700;
    font-size: 16px;
  }
}

.rbc-time-header-cell-single-day{
  width: 100%;
  height: 100px;
  display: flex;
  pointer-events: none;
}

.rbc-allday-cell{
  height: 70px !important;
}

.conflict-label{
  width: 100px;
  color:#fa8c16;
  padding: 2px 5px;
  border-radius: 5px;
  background-color: #fff7e6;
  border: 1px solid #ffd591;
  display: flex;
  align-items: center;
  justify-content: space-between;
}